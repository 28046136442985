<template>
  <div :class="`${fieldClass}--policy-select`">
    <div class="form-group">
      <label>Policy:</label>
      <select-field
        v-model="selectedPolicyIndex"
        :field="{ options: policyOptions }"
      />
    </div>
    <div
      v-if="termOptions"
      class="form-group"
    >
      <label>Term:</label>
      <select-field
        v-model="selectedTermId"
        :field="{ options: termOptions }"
      />
    </div>
    <div
      v-if="selectedTerm"
      class="form-group"
    >
      <label>Price:</label>
      <currency-field
        v-model="price"
        :min="selectedTerm.limits.lower"
        :max="selectedTerm.limits.upper"
      />
    </div>
  </div>
</template>

<script>
import { fieldMixin } from '@itccompliance/compliance-vue-essentials-plugin';
import CurrencyField from '@/components/form/fields/Currency.vue';
import SelectField from '@itccompliance/compliance-vue-essentials-plugin/src/components/bootstrap-vue/form/fields/select.vue';

export default {
  name: 'PolicySelectField',
  components: { CurrencyField, SelectField },
  mixins: [fieldMixin],
  data: () => ({
    selectedPolicyIndex: null,
    selectedTermId: null,
    price: null,
  }),
  computed: {
    policyOptions() {
      return this.field.options.reduce((r, { name: text }, value) => [
        ...r,
        { text, value },
      ], []);
    },
    termOptions() {
      if (this.selectedPolicyIndex === null) return null;
      return Object.values(this.field.options[this.selectedPolicyIndex].terms)
        .reduce((r, { label: text, id: value }) => [
          ...r,
          { text, value },
        ], []);
    },
    selectedTerm() {
      if (!this.selectedTermId) return null;
      return Object.values(this.field.options[this.selectedPolicyIndex].terms)
        .find((t) => t.id === this.selectedTermId);
    },
  },
  watch: {
    selectedPolicyIndex() {
      this.selectedTermId = null;
    },
    selectedTermId(termId) {
      this.price = termId
        ? this.selectedTerm.rrp
        : null;
    },
    price(price) {
      this.$emit(
        'input',
        price
          ? { ...this.selectedTerm, price }
          : null,
      );
    },
  },
};
</script>
