import Repository from '../Repository';

export class AuthRepository extends Repository {
  async login(email, password) {
    const result = await this.client.post('/auth/login', { email, password });
    return result;
  }

  async register(data, token) {
    const result = await this.client.post(`/auth/register/${token}`, data);
    return result;
  }

  async requestPasswordReset(email) {
    const result = await this.client.post('/auth/request-reset', { email });
    return result;
  }

  async resetPassword(data) {
    const result = await this.client.post('/auth/reset', data);
    return result;
  }
}

export default new AuthRepository();
