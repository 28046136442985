var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.fieldClass + "--datepicker",
        "form-control",
        {
          "is-valid": _vm.state === true,
          "is-invalid": _vm.state === false
        }
      ],
      attrs: { id: _vm.id, readonly: _vm.disabled },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.$refs.day[0].focus()
        }
      }
    },
    [
      _vm._l(["day", "month", "year"], function(field, index) {
        return [
          _c(
            "input",
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mutableValue[field],
                    expression: "mutableValue[field]"
                  }
                ],
                key: field + "-input",
                ref: field,
                refInFor: true,
                staticClass: "border-0 text-center p-0",
                attrs: { disabled: _vm.disabled, type: "text" },
                domProps: { value: _vm.mutableValue[field] },
                on: {
                  blur: function($event) {
                    _vm.mutableValue[field] = _vm.formatField(
                      _vm.mutableValue[field],
                      field
                    )
                  },
                  click: function($event) {
                    $event.stopPropagation()
                  },
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.mutableValue, field, $event.target.value)
                    },
                    function($event) {
                      return _vm.maybeGoToNextField($event, field)
                    }
                  ]
                }
              },
              "input",
              _vm.fieldProps[field],
              false
            )
          ),
          index < 2
            ? _c("span", { key: field + "-separator" }, [_vm._v("/")])
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }