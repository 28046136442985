import { utils } from '@itccompliance/compliance-vue-essentials-plugin';
import axios from 'axios';
import EventBus from '@/services/EventBus';

const getBaseUrl = (apiName = '') => {
  let str = 'VUE_APP_API_';
  if ((typeof apiName === 'string') && (apiName.length > 0)) str += `${apiName.replace('-', '_')}_`;
  str += 'URL';
  return str;
};

export default (apiName) => {
  ['post', 'put', 'patch'].forEach((method) => {
    axios.defaults.headers[method]['Content-Type'] = 'application/json';
  });
  const instance = axios.create({ timeout: 0, withCredentials: true });
  instance.defaults.baseURL = process.env[getBaseUrl(apiName)];
  instance.interceptors.response.use((res) => res, (error) => {
    switch (utils.object.deepGet(error, 'response.status')) {
      case 401:
        window.location.href = '/login';
        break;
      case 403:
      case 422:
        break;
      default:
        EventBus.$emit('api-error', utils.object.deepGet(error, 'response.data'));
    }
    return Promise.reject(error.response);
  });
  return instance;
};
