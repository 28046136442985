<template>
  <div class="home h-100 d-flex align-items-center">
    <b-nav
      class="mx-auto mw-lg-50 flex-grow-1 flex-column flex-md-row"
      align="center"
      justified
    >
      <b-nav-item
        :to="{ name: 'Create Sale' }"
        link-classes="m-3 btn btn-lg btn-dark"
      >
        <b-icon-file-earmark-plus class="mr-2" />
        New Sale
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'List Sales' }"
        link-classes="m-3 btn btn-lg btn-dark"
      >
        <b-icon-view-list class="mr-2" />
        Existing Sales
      </b-nav-item>
    </b-nav>
  </div>
</template>
<script>
import { BIconFileEarmarkPlus, BIconViewList } from 'bootstrap-vue';

export default {
  components: {
    BIconViewList,
    BIconFileEarmarkPlus,
  },
};
</script>
