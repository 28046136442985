import Repository from '../Repository';

export class RootRepository extends Repository {
  async fetchDealers() {
    const { data } = await this.client.get('dealers');
    return data.data;
  }

  async setDealer(dealer) {
    const { data } = await this.client.post(`auth/change-dealer/${dealer}`);
    return data.data;
  }
}

export default new RootRepository();
