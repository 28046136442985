<template>
  <div
    :id="id"
    :class="`${fieldClass}--create-sale-radio`"
  >
    <b-btn
      v-for="option of options"
      :key="option.value"
      :class="`text-${typeof state === 'boolean'
        ? state
          ? mutableValue === option.value
            ? 'success'
            : 'dark'
          : 'danger'
        : 'dark'}`"
      :disabled="disabled"
      variant="link"
      @click="mutableValue = option.value"
    >
      <component
        :is="iconMap[option.value]"
        :class="['d-block mx-auto mb-2', `${fieldClass}--create-sale-radio__icon`]"
      />
      <span>
        {{ option.text }}
      </span>
    </b-btn>
  </div>
</template>

<script>
import {
  BIconBuilding,
  BIconPeople,
  BIconShop,
  BIconTelephone,
} from 'bootstrap-vue';
import { fieldMixin, utils } from '@itccompliance/compliance-vue-essentials-plugin';

export default {
  name: 'CreateSaleRadioField',
  components: {
    BIconBuilding,
    BIconPeople,
    BIconShop,
    BIconTelephone,
  },
  mixins: [fieldMixin],
  data: () => ({
    iconMap: {
      retail: 'BIconShop',
      commercial: 'BIconBuilding',
      'face-to-face': 'BIconPeople',
      telesale: 'BIconTelephone',
    },
  }),
  computed: {
    options() {
      const options = utils.parsers.parseObject(utils.object.deepGet(this, 'field.options'));
      let radioOptions = [];
      if (Array.isArray(options)) {
        radioOptions = options.map((option) => {
          if (typeof option === 'object') return option;
          return { value: option, text: option };
        });
      } else {
        radioOptions = Object.keys(options).map((key) => ({
          value: key,
          ...(typeof options[key] === 'object'
            ? options[key]
            : { text: options[key] }
          ),
        }));
      }
      return radioOptions;
    },
  },
};
</script>
