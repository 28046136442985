var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.fieldClass + "--day-of-month"], attrs: { id: _vm.id } },
    [
      _c("b-select", {
        attrs: { options: _vm.dayOptions },
        model: {
          value: _vm.mutableValue,
          callback: function($$v) {
            _vm.mutableValue = $$v
          },
          expression: "mutableValue"
        }
      }),
      _vm.transformMutableValue(_vm.mutableValue)
        ? _c("b-form-text", [
            _vm._v(" Your first payment will be taken on or after: "),
            _c("strong", [_vm._v(" " + _vm._s(_vm.firstPayment) + " ")])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }