var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column vh-100", attrs: { id: "app" } },
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", type: "dark", variant: "black" } },
        [
          _c("b-navbar-brand", { attrs: { href: "/" } }, [
            _c("img", { attrs: { src: require("@/assets/img/logo.svg") } })
          ]),
          _vm.currentDealer
            ? [
                _c("strong", {
                  staticClass: "text-light ml-3",
                  domProps: { textContent: _vm._s(_vm.currentDealer.name) }
                }),
                _vm.dealers.length > 1
                  ? _c("b-icon-caret-down-fill", {
                      staticClass: "text-light ml-2 btn btn-text p-0",
                      on: { click: _vm.changeDealer }
                    })
                  : _vm._e()
              ]
            : _vm._e(),
          !_vm.$route.meta.public
            ? [
                _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
                _c(
                  "b-collapse",
                  { attrs: { id: "nav-collapse", "is-nav": "" } },
                  [
                    _c(
                      "b-navbar-nav",
                      { staticClass: "ml-auto" },
                      [
                        _c(
                          "b-nav-item",
                          {
                            attrs: {
                              to: { name: "Home" },
                              "link-classes": "text-center",
                              exact: ""
                            }
                          },
                          [_vm._v(" Home ")]
                        ),
                        _c(
                          "b-nav-item",
                          {
                            attrs: {
                              to: { name: "Create Sale" },
                              "link-classes": "text-center",
                              exact: ""
                            }
                          },
                          [_vm._v(" New Sale ")]
                        ),
                        _c(
                          "b-nav-item",
                          {
                            attrs: {
                              to: { name: "List Sales" },
                              "link-classes": "text-center",
                              exact: ""
                            }
                          },
                          [_vm._v(" Existing Sales ")]
                        ),
                        _c(
                          "b-nav-item",
                          {
                            attrs: {
                              href: "/logout",
                              "link-classes": "text-center"
                            }
                          },
                          [_vm._v(" Logout ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "b-container",
        { staticClass: "flex-grow-1" },
        [
          _c(
            "b-row",
            { staticClass: "h-100" },
            [_c("b-col", { staticClass: "py-5" }, [_c("router-view")], 1)],
            1
          )
        ],
        1
      ),
      _c(
        "footer",
        { staticClass: "bg-black text-light py-5", attrs: { id: "footer" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.mercedes-amg.com/en.html",
                          target: "_blank"
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/logo_amg.svg"),
                            alt: "AMG - Driving Performance"
                          }
                        })
                      ]
                    )
                  ]),
                  _c("b-col", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.daimler.com/en/",
                          target: "_blank"
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/logo_daimler.svg"),
                            alt: "Daimler AG"
                          }
                        })
                      ]
                    )
                  ]),
                  _c("b-col", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.smart.com/",
                          target: "_blank"
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/logo_smart.svg"),
                            alt: "Smart"
                          }
                        })
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("compliance-prompt-container")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }