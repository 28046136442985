import Repository from '../../Repository';

export class SaleStepsRepository extends Repository {
  async fetchSteps(saleId) {
    const { data } = await this.client.get(`sales/${saleId}/steps`);
    return data.data;
  }

  async fetchStep(saleId, key) {
    const { data } = await this.client.get(`sales/${saleId}/steps/${key}`);
    return data.data;
  }

  async saveStep(saleId, key, postData) {
    const { data } = await this.client.post(`sales/${saleId}/steps/${key}`, postData);
    return data.data;
  }
}

export default new SaleStepsRepository();
