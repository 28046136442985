var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input-group",
    {
      class: _vm.fieldClass + "--currency",
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function() {
            return [
              _c("b-input-group-text", {
                domProps: { textContent: _vm._s(_vm.symbol) }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-form-input", {
        attrs: {
          id: _vm.id,
          disabled: _vm.disabled,
          min: _vm.min ? _vm.transformValue(_vm.min) : 0,
          max: _vm.max ? _vm.transformValue(_vm.max) : null,
          state: _vm.state,
          step: "0.01",
          type: "number"
        },
        model: {
          value: _vm.mutableValue,
          callback: function($$v) {
            _vm.mutableValue = $$v
          },
          expression: "mutableValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }