<template>
  <div
    id="app"
    class="d-flex flex-column vh-100"
  >
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="black"
    >
      <b-navbar-brand href="/">
        <img src="@/assets/img/logo.svg">
      </b-navbar-brand>
      <template v-if="currentDealer">
        <strong
          class="text-light ml-3"
          v-text="currentDealer.name"
        />
        <b-icon-caret-down-fill
          v-if="dealers.length > 1"
          class="text-light ml-2 btn btn-text p-0"
          @click="changeDealer"
        />
      </template>
      <template v-if="!$route.meta.public">
        <b-navbar-toggle target="nav-collapse" />
        <b-collapse
          id="nav-collapse"
          is-nav
        >
          <b-navbar-nav class="ml-auto">
            <b-nav-item
              :to="{ name: 'Home' }"
              link-classes="text-center"
              exact
            >
              Home
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'Create Sale' }"
              link-classes="text-center"
              exact
            >
              New Sale
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'List Sales' }"
              link-classes="text-center"
              exact
            >
              Existing Sales
            </b-nav-item>
            <b-nav-item
              href="/logout"
              link-classes="text-center"
            >
              Logout
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </template>
    </b-navbar>
    <b-container class="flex-grow-1">
      <b-row class="h-100">
        <b-col class="py-5">
          <router-view />
        </b-col>
      </b-row>
    </b-container>
    <footer
      id="footer"
      class="bg-black text-light py-5"
    >
      <b-container>
        <b-row>
          <b-col>
            <a
              href="https://www.mercedes-amg.com/en.html"
              target="_blank"
            >
              <img
                src="@/assets/img/logo_amg.svg"
                alt="AMG - Driving Performance"
              >
            </a>
          </b-col>
          <b-col>
            <a
              href="https://www.daimler.com/en/"
              target="_blank"
            >
              <img
                src="@/assets/img/logo_daimler.svg"
                alt="Daimler AG"
              >
            </a>
          </b-col>
          <b-col>
            <a
              href="https://www.smart.com/"
              target="_blank"
            >
              <img
                src="@/assets/img/logo_smart.svg"
                alt="Smart"
              >
            </a>
          </b-col>
        </b-row>
      </b-container>
    </footer>
    <compliance-prompt-container />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { BIconCaretDownFill } from 'bootstrap-vue';
import EventBus from '@/services/EventBus';

export default {
  components: { BIconCaretDownFill },
  computed: {
    ...mapGetters(['dealers']),
    currentDealer() {
      return this.dealers.find((d) => d.current)
        || this.dealers[0]
        || null;
    },
  },
  created() {
    EventBus.$on('api-error', this.showToast);
  },
  methods: {
    ...mapActions(['setDealer']),
    changeDealer() {
      this.$compliancePrompt.prompt({
        title: 'Please select a dealer',
        formModel: {
          fields: [{
            id: 'dealer',
            display_type: 'select',
            options: this.dealers.reduce((r, d) => [
              ...r,
              { value: d.id, text: d.name },
            ], []),
            rules: ['required'],
            value: this.currentDealer.id,
          }],
        },
      }).then(this.setDealer);
    },
    showToast(err) {
      const config = {
        autoHideDelay: 5000,
        title: err.title,
        variant: 'danger',
      };
      this.$bvToast.toast(err.details || 'error', config);
    },
  },
};
</script>
