import authRepository from '@/api/repositories/auth';

export default {
  namespaced: true,
  actions: {
    async login(_, { email, password }) {
      await authRepository.login(email, password);
    },
    async register(_, { data, token }) {
      const result = await authRepository.register(data, token);
      return result;
    },
    async requestPasswordReset(_, { email }) {
      const result = await authRepository.requestPasswordReset(email);
      return result;
    },
    async resetPassword(_, data) {
      const result = await authRepository.resetPassword(data);
      return result;
    },
  },
};
