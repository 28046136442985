<template>
  <div
    :id="id"
    :class="[
      `${fieldClass}--datepicker`,
      'form-control',
      {
        'is-valid': state === true,
        'is-invalid': state === false,
      },
    ]"
    :readonly="disabled"
    @click.stop="$refs.day[0].focus()"
  >
    <template v-for="(field, index) of ['day', 'month', 'year']">
      <input
        :key="`${field}-input`"
        :ref="field"
        v-model="mutableValue[field]"
        class="border-0 text-center p-0"
        :disabled="disabled"
        type="text"
        v-bind="fieldProps[field]"
        @blur="mutableValue[field] = formatField(mutableValue[field], field)"
        @click.stop
        @input="maybeGoToNextField($event, field)"
      >
      <span
        v-if="index < 2"
        :key="`${field}-separator`"
      >/</span>
    </template>
  </div>
</template>

<script>
import { isValid, parseISO, format } from 'date-fns';
import { fieldMixin, utils } from '@itccompliance/compliance-vue-essentials-plugin';

export default {
  name: 'DateField',
  mixins: [fieldMixin],
  data: () => ({
    fieldProps: {
      day: {
        maxlength: 2,
        placeholder: 'dd',
        style: 'width:2em',
      },
      month: {
        maxlength: 2,
        placeholder: 'mm',
        style: 'width:2em',
      },
      year: {
        maxlength: 4,
        placeholder: 'yyyy',
        style: 'width:3em',
      },
    },
    format: 'yyyy-MM-dd',
    referenceDate: new Date(),
  }),
  methods: {
    maybeGoToNextField(e, field) {
      if (field !== 'year') {
        const nextFields = { day: 'month', month: 'year' };
        if (e.target.value.length === this.fieldProps[field].maxlength) {
          this.$refs[nextFields[field]][0].focus();
        }
      }
    },
    formatField(value, field) {
      if (!value) return null;
      const num = utils.parsers.parseNumber(value, {
        asInt: true,
        defaultReturn: 0,
      });
      if (field === 'year') {
        if (num < 100) {
          const currentYear = this.referenceDate.getFullYear();
          return ((currentYear - num < 2000 ? 1900 : 2000) + num).toString();
        }
        return num.toString().padStart(4, '0');
      }
      return num.toString().padStart(2, '0');
    },
    parseInitialValue(value) {
      return this.transformValue(value) || {
        day: null,
        month: null,
        year: null,
      };
    },
    transformValue(value) {
      try {
        const date = parseISO(value);
        if (isValid(date)) {
          return {
            day: this.formatField(date.getDate(), 'day'),
            month: this.formatField(date.getMonth() + 1, 'month'),
            year: this.formatField(date.getFullYear(), 'year'),
          };
        }
      } catch { /**/ }
      return this.mutableValue;
    },
    transformMutableValue(mutableValue) {
      // Emit null if date is not valid
      try {
        const { year, month, day } = mutableValue;
        const toNumber = (val) => utils.parsers.parseNumber(val, { asInt: true });
        const date = new Date(toNumber(year), toNumber(month) - 1, toNumber(day));
        const formatted = format(date, this.format);
        if (`${year}-${month}-${day}` === formatted) return formatted;
      } catch { /**/ }
      return null;
    },
  },
};
</script>
