import saleRepository from '@/api/repositories/sale';
import modules from './modules';

export default {
  namespaced: true,
  state: {
    sale: { documents: null },
    saleCreateForm: null,
  },
  getters: {
    sale: ({ sale }) => sale,
    saleCreateForm: ({ saleCreateForm }) => saleCreateForm,
  },
  mutations: {
    SET_SALE(state, sale) {
      state.sale = { ...state.sale, ...sale };
    },
    SET_SALE_CREATE_FORM(state, form) {
      state.saleCreateForm = form;
    },
    SET_SALE_DOCUMENTS(state, documents) {
      state.sale.documents = documents;
    },
  },
  actions: {
    async fetchSaleCreateForm({ commit }) {
      const form = await saleRepository.fetchSaleCreateForm();
      commit('SET_SALE_CREATE_FORM', form);
    },

    async fetchSaleDocuments({ commit }, saleId) {
      const documents = await saleRepository.fetchSaleDocuments(saleId);
      commit('SET_SALE_DOCUMENTS', documents);
    },

    async createSale({ commit }, data) {
      const sale = await saleRepository.createSale(data);
      commit('SET_SALE', sale);
      return sale;
    },

    async fetchSale({ commit, dispatch }, { saleId, approach }) {
      const sale = await saleRepository.fetchSale(saleId, approach);
      await commit('SET_SALE', sale);
      await commit('SET_SALE_DOCUMENTS', []);
      await dispatch('sale/steps/resetForms', saleId, { root: true });
      await dispatch('sale/steps/fetchSteps', saleId, { root: true });
      return sale;
    },

    async fetchSales(_, { pageNumber, searchTerm }) {
      const sales = await saleRepository.fetchSales(pageNumber, searchTerm);
      return sales;
    },

    async overrideProductMoratorium({ state, commit }, productId) {
      const registration = await saleRepository.overrideProductMoratorium(state.sale.id, productId);
      await commit('sale/steps/SET_STEP_FORM', { registration }, { root: true });
    },
  },
  modules,
};
