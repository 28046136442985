var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.fieldClass + "--policy-select" }, [
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("Policy:")]),
        _c("select-field", {
          attrs: { field: { options: _vm.policyOptions } },
          model: {
            value: _vm.selectedPolicyIndex,
            callback: function($$v) {
              _vm.selectedPolicyIndex = $$v
            },
            expression: "selectedPolicyIndex"
          }
        })
      ],
      1
    ),
    _vm.termOptions
      ? _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Term:")]),
            _c("select-field", {
              attrs: { field: { options: _vm.termOptions } },
              model: {
                value: _vm.selectedTermId,
                callback: function($$v) {
                  _vm.selectedTermId = $$v
                },
                expression: "selectedTermId"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.selectedTerm
      ? _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Price:")]),
            _c("currency-field", {
              attrs: {
                min: _vm.selectedTerm.limits.lower,
                max: _vm.selectedTerm.limits.upper
              },
              model: {
                value: _vm.price,
                callback: function($$v) {
                  _vm.price = $$v
                },
                expression: "price"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }