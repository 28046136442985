import CreateSaleRadio from './CreateSaleRadio.vue';
import Currency from './Currency.vue';
import Date from './Date.vue';
import DayOfMonth from './DayOfMonth.vue';
import Hidden from './Hidden.vue';
import PolicySelect from './PolicySelect.vue';

export default [
  CreateSaleRadio,
  Currency,
  Date,
  DayOfMonth,
  Hidden,
  PolicySelect,
];
