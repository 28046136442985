import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/login/reset',
    name: 'Request Password',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ResetPasswordRequest.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/login/reset/:token',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ResetPassword.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/register/:token',
    name: 'Register',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Register.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/sale',
    name: 'List Sales',
    component: () => import(/* webpackChunkName: "sale" */ '../views/sale/List.vue'),
  },
  {
    path: '/sale/create',
    name: 'Create Sale',
    component: () => import(/* webpackChunkName: "sale" */ '../views/sale/Create.vue'),
  },
  {
    path: '/sale/:saleId',
    name: 'View Sale',
    component: () => import(/* webpackChunkName: "sale" */ '../views/sale/View.vue'),
  },
];

const base = process.env.BASE_URL;
const router = new VueRouter({
  mode: 'history',
  base,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => !record.meta.public
      && !store.getters.dealers.length)) {
    store.dispatch('fetchDealers');
  }
  next();
});

export default router;
