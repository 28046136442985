import saleStepsRepository from '@/api/repositories/sale/steps';

export default {
  namespaced: true,
  state: {
    steps: [],
    stepForms: {},
  },
  getters: {
    steps: ({ steps }) => steps,
    stepForms: ({ stepForms }) => stepForms,
  },
  mutations: {
    RESET_FORMS(state) {
      state.stepForms = {};
    },
    SET_STEPS(state, steps) {
      state.steps = steps;
    },
    SET_STEP_FORM(state, data) {
      const { stepForms } = state;
      state.stepForms = {
        ...stepForms,
        ...data,
      };
    },
  },
  actions: {
    async fetchSteps({ commit, rootGetters }) {
      const steps = await saleStepsRepository.fetchSteps(rootGetters['sale/sale'].id);
      commit('SET_STEPS', steps);
      return steps;
    },
    async fetchStep({ commit, rootGetters }, key) {
      const stepForm = {};
      stepForm[key] = await saleStepsRepository.fetchStep(rootGetters['sale/sale'].id, key);
      commit('SET_STEP_FORM', stepForm);
      return stepForm[key];
    },
    resetForms({ commit }) {
      commit('RESET_FORMS');
    },
    async saveStep({ dispatch, rootGetters }, { key, data }) {
      const saleId = rootGetters['sale/sale'].id;
      await saleStepsRepository.saveStep(saleId, key, data);
      await dispatch('sale/fetchSale', { saleId }, { root: true });
      if (key === 'registration') await dispatch('fetchStep', key);
    },
  },
};
