var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home h-100 d-flex align-items-center" },
    [
      _c(
        "b-nav",
        {
          staticClass: "mx-auto mw-lg-50 flex-grow-1 flex-column flex-md-row",
          attrs: { align: "center", justified: "" }
        },
        [
          _c(
            "b-nav-item",
            {
              attrs: {
                to: { name: "Create Sale" },
                "link-classes": "m-3 btn btn-lg btn-dark"
              }
            },
            [
              _c("b-icon-file-earmark-plus", { staticClass: "mr-2" }),
              _vm._v(" New Sale ")
            ],
            1
          ),
          _c(
            "b-nav-item",
            {
              attrs: {
                to: { name: "List Sales" },
                "link-classes": "m-3 btn btn-lg btn-dark"
              }
            },
            [
              _c("b-icon-view-list", { staticClass: "mr-2" }),
              _vm._v(" Existing Sales ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }