import Vue from 'vue';

Vue.mixin({
  data: () => ({
    cardProps: {
      headerClass: 'h3',
      borderVariant: 'primary',
      headerBgVariant: 'white',
      headerBorderVariant: 'white',
      class: [
        'border-top-0',
        'border-right-0',
        'border-bottom-0',
        'border-left-3',
      ],
    },
  }),
});
