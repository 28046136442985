<template>
  <input
    type="hidden"
    v-bind="{ value }"
  >
</template>
<script>
import { fieldMixin } from '@itccompliance/compliance-vue-essentials-plugin';

export default {
  name: 'HiddenField',
  mixins: [fieldMixin],
};
</script>
