import Repository from '../../Repository';

export class SaleRepository extends Repository {
  async fetchSaleCreateForm() {
    const { data } = await this.client.get('sales/init');
    return data.data;
  }

  async fetchSaleDocuments(saleId) {
    const { data } = await this.client.get(`sales/${saleId}/documents`);
    return data.data;
  }

  async createSale(postData) {
    const { data } = await this.client.post('sales', postData);
    return data.data;
  }

  async fetchSale(saleId, approach) {
    const { data } = await this.client.get(
      `sales/${saleId}`,
      { params: approach || undefined },
    );
    return data.data;
  }

  async fetchSales(pageNumber, searchTerm) {
    const { data } = await this.client.get('sales', {
      params: {
        page: pageNumber,
        term: searchTerm || undefined,
      },
    });
    return data;
  }

  async overrideProductMoratorium(saleId, productId) {
    const { data } = await this.client.post(`sales/${saleId}/products/${productId}/moratorium`);
    return data.data;
  }
}

export default new SaleRepository();
