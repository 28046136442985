import Vue from 'vue';
import Vuex from 'vuex';
import rootRepository from '@/api/repositories/root';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    dealers: [],
  },
  mutations: {
    SET_DEALERS(state, dealers) {
      state.dealers = dealers;
    },
  },
  actions: {
    async fetchDealers({ commit }) {
      const dealers = await rootRepository.fetchDealers();
      commit('SET_DEALERS', dealers);
      return dealers;
    },
    async setDealer(_, { dealer }) {
      await rootRepository.setDealer(dealer);
      window.location.reload();
    },
  },
  getters: {
    dealers: ({ dealers }) => dealers,
  },
  modules,
});
