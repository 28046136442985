import Vue from 'vue';
import essentialsPlugin from '@itccompliance/compliance-vue-essentials-plugin';
import customFields from '@/components/form/fields/';
import customValidators from '@/validators/';
import store from '@/store';

Vue.use(essentialsPlugin, {
  customFields,
  customValidators,
  excludedFields: ['currency', 'date'],
  store,
});
