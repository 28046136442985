import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './filters';
import './plugins/essentials';
import './mixins/card';
import '@/scss/index.scss';

Vue.config.productionTip = false;

Vue.use(BootstrapVue, {
  BToast: {
    toaster: 'b-toaster-bottom-right',
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
