<template>
  <div
    :id="id"
    :class="[
      `${fieldClass}--day-of-month`,
    ]"
  >
    <b-select
      v-model="mutableValue"
      :options="dayOptions"
    />
    <b-form-text v-if="transformMutableValue(mutableValue)">
      Your first payment will be taken on or after:
      <strong>
        {{ firstPayment }}
      </strong>
    </b-form-text>
  </div>
</template>

<script>
import {
  isValid, parseISO, format, formatISO, isFuture,
} from 'date-fns';
import { fieldMixin } from '@itccompliance/compliance-vue-essentials-plugin';

export default {
  name: 'DayOfMonthField',
  mixins: [fieldMixin],
  computed: {
    dayOptions() {
      const date = new Date();
      return [
        { value: null, text: '--- Please select a value ---' },
        ...Array.from(new Array(28)).map((_, ix) => ({
          value: ix + 1,
          text: format(date.setDate(ix + 1), 'do'),
        })),
      ];
    },
    firstPayment() {
      const date = parseISO(this.value);
      return isValid(date)
        ? format(date, 'do MMMM yyyy')
        : null;
    },
  },
  methods: {
    transformValue(value) {
      // before display
      const date = parseISO(value);
      if (isValid(date)) {
        return parseInt(format(date, 'd'), 10);
      }
      return null;
    },
    transformMutableValue(mutableValue) {
      // before emit
      if (!mutableValue) return null;
      const date = new Date();
      date.setDate(mutableValue);
      if (!isFuture(date)) {
        date.setMonth(date.getMonth() + 1);
      }
      return formatISO(date, { representation: 'date' });
    },
  },
};
</script>
